import React, { Fragment } from "react";
import Hero from "./sections/hero";
import WhatWeDo from "./sections/what-we-do";
import OurLocations from "./sections/our-locations";
import OurServices from "./sections/our-services";

function Index(): JSX.Element {
  return (
    <Fragment>
      <Hero />
      <WhatWeDo />
      <OurLocations />
      <OurServices />
    </Fragment>
  );
}

export default Index;
