import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Index from "./components/landing";
import AboutUs from "./components/about-us";
import CodeOfConduct from "./components/code-of-conduct";
import OwnershipDisclosure from "./components/ownership-disclosure";
import Footer from "./components/blacklusion-footer/footer";
import Error404 from "./components/error-404";

function App(): JSX.Element {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/code-of-conduct" element={<CodeOfConduct />} />
          <Route path="/ownership-disclosure" element={<OwnershipDisclosure />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="/" element={<Index />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
      <Footer pathsAreRelative={true} />
    </div>
  );
}

export default App;
