import React from "react";
import { Container } from "react-bootstrap";

function Error404(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1>
        <em>404</em>
      </h1>
      <h2>Not Found</h2>
      <a href={"/"}>[ Go back ]</a>
    </Container>
  );
}

export default Error404;
