import React, { useEffect, useRef, useState } from "react";
import NET from "vanta/dist/vanta.net.min";
import * as THREE from "three";
import Typewriter from "typewriter-effect";

function Hero(): JSX.Element {
  const [vantaEffect, setVantaEffect] = useState<any>(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          THREE,
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 100.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 0.6,
          color: "#1d515b",
          backgroundColor: "#000000",
          points: 13.0,
          maxDistance: 27.0,
          spacing: 18.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div ref={myRef} className={"background-effect"}>
      <img src={"https://blacklusion.io/resources/blacklusion_logo.svg"} className="blacklusion-logo" alt="logo" />
      <h1>
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter: any) => {
            typewriter
              .typeString("Block Producer")
              .pauseFor(2500)
              .deleteAll()
              .typeString("Building <em>validation</em> tools")
              .pauseFor(2500)
              .deleteChars(16)
              .typeString("<em>NFT</em> based games")
              .pauseFor(2500)
              .deleteChars(16)
              .typeString("a <em>decentralized</em> future")
              .pauseFor(2500)
              .start();
          }}
        />
      </h1>
    </div>
  );
}

export default Hero;
