import React from "react";
import { Container } from "react-bootstrap";

function OwnershipDisclosure(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1>[Ownership disclosure]</h1>
      <p>
        Blacklusion is operated by the company <em>55 Stores UG (haftungsbeschränkt)</em>. The company and its
        associated brands are owned and represented by Leonard Scheidemantel.
        <br />
        <br />
        No outside investments were taken.
      </p>
    </Container>
  );
}

export default OwnershipDisclosure;
