import React from "react";
import { Container } from "react-bootstrap";

function CodeOfConduct(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1>[Code of Conduct]</h1>
      <p>
        Blacklusion is striving to make blockchain accessible and easy to use, by helping to build and supply the needed
        tools and infrastructure as a block producer. As a representative of the blockchain community, our highest
        priority is to help to achieve the community's goals through our decision making.
        <br />
        <br />
        We are not associated with any other block producers or specific blockchains in any way. As a privately-owned
        company, we have complete control over our actions.
      </p>
    </Container>
  );
}

export default CodeOfConduct;
