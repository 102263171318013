import React from "react";
import { Container } from "react-bootstrap";

function OurServices(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1>[Our services]</h1>
      <div className={"sub-section small left"} style={{ display: "inline-block" }}>
        <h2>Validationcore</h2>
        <p>
          A lightweight toolkit for validating the functioning of EOSIO endpoints. It creates validations on a set
          interval and allows to create historical insights of blockchain nodes.
          <br />
          <br />
          <a href="https://validationcore.io" target="_blank">
            <em>https://validationcore.io</em>
          </a>
        </p>
      </div>

      <div className={"sub-section small right"}>
        <h2>OneApi</h2>
        <p>
          Our Api-As-Service product: A private blockchain node network with all major EOSIO Api types. Easy management
          with our custom dashboard, allowing to view analytics invite team members and more
          <br />
          <br />
          <a href="https://oneapi.dev" target="_blank">
            <em>https://oneapi.dev</em>
          </a>
        </p>
      </div>

      <div className={"sub-section left small last"}>
        <h2>Crosschain Gaming Platform</h2>
        <p>
          Launching soon... A gaming platform that bridges the gap between multiple chains and marketplaces, by provided
          an unbiased way of finding games and managing assets across chains
        </p>
      </div>
    </Container>
  );
}

export default OurServices;
