import React from "react";
import { Container } from "react-bootstrap";

function AboutUs(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1 className={"first"}>[About us]</h1>
      <div className={"sub-section center"}>
        <h2>Disclosure</h2>
        <p>
          Blacklusion is a project operated and maintained by the company <em>55 Stores UG (haftungsbeschränkt)</em>.
        </p>
      </div>
      <div className={"sub-section center last"}>
        <h2>About 55 Stores</h2>
        <p>
          The company 55 Stores UG (haftungsbeschränkt) and its associated brands are represented by the founder and
          CEO, <em>Leonard Scheidemantel</em>.
          <br />
          <br />
          Registered at <em>Amtsgericht Stuttgart, HRB 767186</em>
          <br />
          Value added Tax -ID: <em>DE 321682366</em>
          <br />
          <br />
          Kappelweg 10, 70839 Gerlingen, Germany
        </p>
      </div>
    </Container>
  );
}

export default AboutUs;
