import React from "react";
import { Container } from "react-bootstrap";

function WhatWeDo(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1>[What we do]</h1>
      <p>
        We are a block producer on EOSIO based blockchains. Currently{" "}
        <a href="https://wax.io" target="_blank">
          <em>WAX.io</em>
        </a>{" "}
        is our biggest blockchain that we provide infrastructure and services for. We believe that the current
        implementations and products are mostly targeted to a niche market and tech savvy people. Our goal is to build
        products that are <em>easy to use</em> and help to bring blockchain to a broader audience.
      </p>
    </Container>
  );
}

export default WhatWeDo;
