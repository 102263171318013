import React from "react";
import { Container } from "react-bootstrap";

function OurLocations(): JSX.Element {
  return (
    <Container className={"section"}>
      <h1>[Our locations]</h1>
      <p>
        Our headquarter is based in a small town near <em>Stuttgart, Germany</em>. All of our nodes are located in state
        of the art datacenters spread across the world, but with a focus on the european region.
      </p>
    </Container>
  );
}

export default OurLocations;
