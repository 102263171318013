import React from "react";
import { faGithub, faMediumM, faTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type FooterProps = {
  pathsAreRelative: boolean;
}
/**
 * Footer used by websites operated by blacklusion
 * @constructor
 */
function Footer(props: FooterProps): JSX.Element {
  return (
    <div className="footer">
      <div className="footer-left">
        <a href={props.pathsAreRelative ? "/" : "https://blacklusion.io"}>
          <h1>blacklusion</h1>
        </a>
        <div className="socials">
          <a href="https://github.com/Blacklusion">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href="https://blacklusion.medium.com/">
            <FontAwesomeIcon icon={faMediumM} />
          </a>
          <a href="https://twitter.com/blacklusion">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://t.me/blacklusion">
            <FontAwesomeIcon icon={faTelegram} />
          </a>
        </div>
      </div>
      <ul className="footer-right">
        <li>
          <h2>Our Services</h2>
          <ul className="link-list">
            <li>
              <a href="https://wax.oneapi.dev">OneApi</a>
            </li>
            <li>
              <a href="https://validationcore.blacklusion.io/">Validationcore</a>
            </li>
            <li>
              <a href="https://t.me/waxpagerbot">Pager</a>
            </li>
          </ul>
        </li>
        <li>
          <h2>Links</h2>
          <ul className="link-list">
            <li>
              <a href={"https://blacklusion.medium.com/"}  target="_blank" rel="noopener noreferrer">Blog</a>
            </li>
            <li>
              <a href={props.pathsAreRelative ? "/about-us" : "https://blacklusion.io/about-us"}>About us</a>
            </li>
            <li>
              <a href={props.pathsAreRelative ? "/code-of-conduct" : "https://blacklusion.io/code-of-conduct"}>Code of Conduct</a>
            </li>
            <li>
              <a href={props.pathsAreRelative ? "/ownership-disclosure" : "https://blacklusion.io/ownership-disclosure"}>Ownership Disclosure</a>
            </li>
          </ul>
        </li>
      </ul>
      <div className="footer-bottom">
        <p>
          &copy; All Rights reserved by <a href={props.pathsAreRelative ? "/" : "https://blacklusion.io"}>Blacklusion</a> 2022
        </p>
      </div>
    </div>
  );
}

export default Footer;
